<template>
  <div class="pdf-viewer" :class="{ 'is-loading': loading, 'is-fullscreen': isFullscreen }">
    <!-- Loading Spinner -->
    <div v-if="loading" class="pdf-loading">
      <b-spinner variant="primary" label="Loading PDF"></b-spinner>
      <span class="ml-2">{{ $t('loading') || 'Loading document...' }}</span>
    </div>

    <!-- Error Message -->
    <div v-if="error" class="pdf-error">
      <b-alert show variant="danger">
        <h4 class="alert-heading">{{ $t('pdf.error') || 'Error loading PDF' }}</h4>
        <p>{{ error }}</p>
        <div class="d-flex justify-content-between align-items-center mt-3">
          <b-button variant="outline-danger" @click="reloadViewer">
            <i class="fas fa-sync-alt mr-1"></i>
            {{ $t('retry') || 'Retry' }}
          </b-button>
          <b-button
            variant="primary"
            :href="src"
            target="_blank"
          >
            <i class="fas fa-external-link-alt mr-1"></i>
            {{ $t('pdf.openInNewTab') || 'Open in New Tab' }}
          </b-button>
        </div>
      </b-alert>
    </div>

    <!-- PDF Controls -->
    <div v-if="showControls && !loading && !error" class="pdf-controls">
      <b-button-group class="mr-2">
        <b-button
          :disabled="currentPage <= 1"
          @click="previousPage"
          variant="outline-primary"
          v-b-tooltip.hover
          :title="$t('pdf.previousPage') || 'Previous Page'"
        >
          <i class="fas fa-chevron-left"></i>
        </b-button>
        <b-form-input
          v-model.number="pageInput"
          type="number"
          :min="1"
          :max="numPages"
          class="page-input mx-1"
          @change="handlePageInput"
        />
        <span class="page-total">/ {{ numPages }}</span>
        <b-button
          :disabled="currentPage >= numPages"
          @click="nextPage"
          variant="outline-primary"
          v-b-tooltip.hover
          :title="$t('pdf.nextPage') || 'Next Page'"
        >
          <i class="fas fa-chevron-right"></i>
        </b-button>
      </b-button-group>

      <b-button-group class="mr-2">
        <b-button
          @click="zoomOut"
          variant="outline-primary"
          v-b-tooltip.hover
          :title="$t('pdf.zoomOut') || 'Zoom Out'"
        >
          <i class="fas fa-search-minus"></i>
        </b-button>
        <b-dropdown
          variant="outline-primary"
          :text="Math.round(scale * 100) + '%'"
          class="zoom-dropdown"
        >
          <b-dropdown-item @click="setZoom(0.5)">50%</b-dropdown-item>
          <b-dropdown-item @click="setZoom(0.75)">75%</b-dropdown-item>
          <b-dropdown-item @click="setZoom(1)">100%</b-dropdown-item>
          <b-dropdown-item @click="setZoom(1.25)">125%</b-dropdown-item>
          <b-dropdown-item @click="setZoom(1.5)">150%</b-dropdown-item>
          <b-dropdown-item @click="setZoom(2)">200%</b-dropdown-item>
        </b-dropdown>
        <b-button
          @click="zoomIn"
          variant="outline-primary"
          v-b-tooltip.hover
          :title="$t('pdf.zoomIn') || 'Zoom In'"
        >
          <i class="fas fa-search-plus"></i>
        </b-button>
      </b-button-group>

      <b-button-group>
        <b-button
          @click="rotate"
          variant="outline-primary"
          v-b-tooltip.hover
          :title="$t('pdf.rotate') || 'Rotate'"
        >
          <i class="fas fa-redo"></i>
        </b-button>
        <b-button
          v-if="allowFullscreen"
          @click="toggleFullscreen"
          variant="outline-primary"
          v-b-tooltip.hover
          :title="isFullscreen ? $t('pdf.exitFullscreen') || 'Exit Fullscreen' : $t('pdf.enterFullscreen') || 'Enter Fullscreen'"
        >
          <i :class="isFullscreen ? 'fas fa-compress' : 'fas fa-expand'"></i>
        </b-button>
      </b-button-group>
    </div>

    <!-- PDF Canvas Container -->
    <div class="pdf-canvas-container" ref="canvasContainer">
      <canvas ref="pdfCanvas"></canvas>
    </div>
  </div>
</template>

<script>
// Import PDF.js with proper CommonJS imports for Vue 2
const pdfjsLib = require('pdfjs-dist/legacy/build/pdf');
const pdfjsWorker = require('pdfjs-dist/legacy/build/pdf.worker.entry');

// Import BootstrapVue components
import {
  BSpinner,
  BAlert,
  BButton,
  BButtonGroup,
  BFormInput,
  BDropdown,
  BDropdownItem,
  VBTooltip
} from 'bootstrap-vue';

// Configure worker
pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export default {
  name: 'PdfViewer',

  components: {
    BSpinner,
    BAlert,
    BButton,
    BButtonGroup,
    BFormInput,
    BDropdown,
    BDropdownItem
  },

  directives: {
    'b-tooltip': VBTooltip
  },

  props: {
    src: {
      type: String,
      required: true
    },
    showControls: {
      type: Boolean,
      default: true
    },
    allowFullscreen: {
      type: Boolean,
      default: true
    },
    initialPage: {
      type: Number,
      default: 1
    },
    initialScale: {
      type: Number,
      default: 1
    }
  },

  data() {
    return {
      loading: true,
      error: null,
      currentPage: this.initialPage,
      pageInput: this.initialPage,
      numPages: 0,
      scale: this.initialScale,
      rotation: 0,
      isFullscreen: false,
      pdfDoc: null,
      pageRendering: false,
      pageNumPending: null
    };
  },

  methods: {
    async loadPDF() {
      try {
        this.loading = true;
        this.error = null;
        console.log('Loading PDF from URL:', this.src);

        // Load the PDF document with updated configuration
        const loadingTask = pdfjsLib.getDocument({
          url: this.src,
          verbosity: 0,
          useSystemFonts: true,
          cMapUrl: `${window.location.origin}/pdf.js/cmaps/`,
          cMapPacked: true,
          enableXfa: false,
          useWorkerFetch: false
        });

        this.pdfDoc = await loadingTask.promise;
        this.numPages = this.pdfDoc.numPages;
        
        // Emit loaded event
        this.$emit('loaded', {
          pagesCount: this.numPages,
          currentPage: this.currentPage
        });

        // Render the first page
        await this.renderPage(this.currentPage);
        
        this.loading = false;
      } catch (err) {
        console.error('Error loading PDF:', err);
        this.error = err.message || this.$t('pdf.loadError') || 'Failed to load PDF';
        this.loading = false;
        // Emit error event
        this.$emit('error', err);
      }
    },

    async renderPage(num) {
      if (this.pageRendering) {
        this.pageNumPending = num;
        return;
      }

      try {
        this.pageRendering = true;
        const page = await this.pdfDoc.getPage(num);

        const canvas = this.$refs.pdfCanvas;
        const ctx = canvas.getContext('2d');

        // Calculate viewport size
        const container = this.$refs.canvasContainer;
        const containerWidth = container.clientWidth;
        const viewport = page.getViewport({ 
          scale: this.scale,
          rotation: this.rotation 
        });

        // Adjust canvas size to container width
        const containerScale = containerWidth / viewport.width;
        const adjustedViewport = page.getViewport({ 
          scale: this.scale * containerScale,
          rotation: this.rotation 
        });

        canvas.width = adjustedViewport.width;
        canvas.height = adjustedViewport.height;

        await page.render({
          canvasContext: ctx,
          viewport: adjustedViewport
        }).promise;

        this.pageRendering = false;

        if (this.pageNumPending !== null) {
          await this.renderPage(this.pageNumPending);
          this.pageNumPending = null;
        }
      } catch (err) {
        console.error('Error rendering page:', err);
        this.error = err.message || this.$t('pdf.renderError') || 'Failed to render page';
        this.pageRendering = false;
      }
    },

    async handlePageInput() {
      const page = parseInt(this.pageInput);
      if (page && page >= 1 && page <= this.numPages) {
        this.currentPage = page;
        await this.renderPage(page);
        this.$emit('page-change', page);
      } else {
        this.pageInput = this.currentPage;
      }
    },

    async previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.pageInput = this.currentPage;
        await this.renderPage(this.currentPage);
        this.$emit('page-change', this.currentPage);
      }
    },

    async nextPage() {
      if (this.currentPage < this.numPages) {
        this.currentPage++;
        this.pageInput = this.currentPage;
        await this.renderPage(this.currentPage);
        this.$emit('page-change', this.currentPage);
      }
    },

    async setZoom(scale) {
      this.scale = scale;
      await this.renderPage(this.currentPage);
      this.$emit('scale-change', scale);
    },

    async zoomIn() {
      this.scale = Math.min(this.scale * 1.2, 3);
      await this.renderPage(this.currentPage);
      this.$emit('scale-change', this.scale);
    },

    async zoomOut() {
      this.scale = Math.max(this.scale / 1.2, 0.5);
      await this.renderPage(this.currentPage);
      this.$emit('scale-change', this.scale);
    },

    async rotate() {
      this.rotation = (this.rotation + 90) % 360;
      await this.renderPage(this.currentPage);
    },

    async reloadViewer() {
      await this.loadPDF();
    },

    async toggleFullscreen() {
      try {
        if (!this.isFullscreen) {
          await this.$el.requestFullscreen();
        } else {
          await document.exitFullscreen();
        }
      } catch (err) {
        console.error('Fullscreen error:', err);
      }
    }
  },

  watch: {
    src: {
      handler: 'loadPDF',
      immediate: true
    }
  },

  beforeDestroy() {
    if (this.pdfDoc) {
      this.pdfDoc.destroy();
      this.pdfDoc = null;
    }
  }
};
</script>

<style lang="scss" scoped>
.pdf-viewer {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 400px;
  background-color: var(--light, #f8f9fa);
  border: 1px solid var(--border-color, #dee2e6);
  border-radius: 0.25rem;
  overflow: hidden;

  &.is-fullscreen {
    border: none;
    border-radius: 0;
  }

  &.is-loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.pdf-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.9);
  padding: 1rem;
  border-radius: 0.25rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 2;
}

.pdf-error {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 500px;
  z-index: 2;
}

.pdf-controls {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem;
  background: rgba(255, 255, 255, 0.95);
  border-bottom: 1px solid var(--border-color, #dee2e6);
  z-index: 1;
  backdrop-filter: blur(5px);

  .page-input {
    width: 60px;
    text-align: center;
    padding: 0.25rem;
    height: auto;
  }

  .page-total {
    display: inline-block;
    padding: 0.375rem 0.75rem;
    color: var(--secondary, #6c757d);
  }

  .zoom-dropdown {
    button {
      min-width: 80px;
    }
  }
}

.pdf-canvas-container {
  width: 100%;
  height: calc(100% - 56px);
  overflow: auto;
  display: flex;
  justify-content: center;
  background-color: var(--light, #f8f9fa);
  padding: 1rem;

  canvas {
    max-width: 100%;
    height: auto;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    background-color: white;
  }
}

// Fullscreen styles
:fullscreen {
  background: var(--light, #f8f9fa);
  
  .pdf-canvas-container {
    height: calc(100vh - 56px);
  }
}
</style> 